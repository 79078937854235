.root {
  @apply grid-cols-3 gap-8 sm:grid;
}

.info {
  @apply col-span-2 mt-10 flex flex-col lg:justify-center space-y-8 sm:mt-0;
}

.title {
  @apply h3 md:h2;
}

.presaleTagContainer {
  @apply absolute top-5 left-3 z-[2];
  @apply sm:top-1 sm:left-1;
}
.presaleTag {
  @apply inline-block min-w-[110px] rounded-lg bg-secondary py-1 px-2 text-center text-sm uppercase
  text-neutral-900 opacity-90;
  @apply sm:min-w-[80px] sm:text-xs;
  @apply lg:py-2 lg:px-4 lg:text-base;
}

.countdownWrapper {
  @apply flex w-full flex-col items-center sm:max-w-[274px];
}

.premierDate {
  @apply h4 mt-2 block text-center md:text-left;
}

.synopsis {
  @apply line-clamp-3;
}

.buttons {
  @apply flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0;
}
.dateSelector {
  @apply justify-start;
}
.dateSelectorButton {
  @apply from-neutral-800 to-transparent dark:from-neutral-800 dark:to-transparent;
}
/*.btnPrimary {
  @apply btn btn-primary btn-xl;
}

.btnTrailer {
  @apply btn btn-xl btn-dark;
}*/

.onPresale .premierDate {
  @apply text-left;
}
