.root {
  @apply relative py-12 text-center;
}

.wrapper {
  @apply mx-auto flex space-x-6 overflow-x-auto scroll-smooth py-2 px-8 scrollbar-hide lg:max-w-full xl:inline-flex;
}

.movie {
  @apply aspect-poster min-w-[207px] flex-1 text-center;
}

.title {
  @apply mt-3 line-clamp-2;
}

.originalTitle {
  @apply quote hidden;
}

.date {
  @apply block text-sm text-secondary;
}

.buttons {
  @apply hidden;
}

.quintet {
  .wrapper {
    @apply lg:container lg:grid lg:grid-cols-5 lg:gap-6 lg:space-x-0 lg:px-[3rem] lg:px-[4rem];
  }

  .movie {
    @apply lg:min-w-[150px];
  }
}

.quartet {
  .wrapper {
    @apply md:container md:grid  md:grid-cols-4 md:gap-6 md:space-x-0 md:px-12;
  }

  .movie {
    @apply md:min-w-[150px];
  }
}

.trio {
  .wrapper {
    @apply md:container md:grid md:grid-cols-3 md:gap-8 md:space-x-0 md:px-[2rem] lg:px-[4rem];
  }

  .movie {
    @apply text-left md:min-w-[150px];
  }

  .content {
    @apply space-y-4;
  }

  .title {
    @apply h3 line-clamp-2;
  }

  .originalTitle {
    @apply line-clamp-1;
  }

  .date {
    @apply callout text-secondary;
  }
}

.duo {
  .wrapper {
    @apply md:container md:space-x-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:px-[3rem] lg:px-[4rem];
  }

  .movie {
    @apply md:flex md:aspect-auto md:flex-row md:items-center md:space-x-4 md:text-left;
  }

  .poster {
    @apply aspect-poster md:w-[100px] lg:w-[150px] xl:w-[210px];
  }

  .content {
    @apply flex-1 space-y-3;
  }

  .title {
    @apply md:h4 md:mt-0 lg:h2 lg:h3;
  }

  .originalTitle {
    @apply quote hidden lg:block lg:line-clamp-2;
  }

  .buttons {
    @apply hidden space-x-4 md:flex;
  }
}

.unique {
  .movie {
    @apply md:flex md:aspect-auto md:max-w-[500px] md:flex-row md:items-center md:space-x-4 md:text-left;
  }

  .poster {
    @apply aspect-poster md:w-[100px] lg:w-[150px] xl:w-[210px];
  }

  .content {
    @apply flex-1 space-y-3;
  }

  .title {
    @apply md:h4 md:mt-0 lg:h2 lg:h3;
  }

  .originalTitle {
    @apply quote hidden lg:block lg:line-clamp-2;
  }

  .buttons {
    @apply hidden space-x-4 md:flex;
  }
}

.arrowBtn {
  @apply pointer-events-none absolute h-full w-[64px] from-neutral-800 to-neutral-800/0 text-4xl
  text-transparent opacity-0 hidden transition-opacity md:w-[100px] md:text-6xl lg:pointer-events-auto
  lg:text-white;
}

.buttonLeft {
  @apply top-0 left-0 bg-gradient-to-r opacity-100 block;
}

.buttonRight {
  @apply top-0 right-0 bg-gradient-to-l opacity-100 block;
}
