.root {
  @apply space-y-4 text-body;
}

.light {
  & .title,
  & .projectionName {
    @apply text-white;
  }
}

.dark {
  & .title,
  & .projectionName {
    @apply text-body;
  }
}
.default {
  & .title,
  & .projectionName {
    @apply text-body;
  }
}

.title {
  @apply h4 mb-4 block;
}

.projection {
  @apply mb-4 space-y-3;
}

.show {
  @apply btn btn-sm  whitespace-nowrap text-base text-neutral-800;
}

.disabled {
  @apply opacity-60;
}

.schedules {
  @apply grid grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-4;
}

.normalRooms {
  & .show {
    @apply btn-primary;
  }
}

.premierRooms {
  & .title {
    @apply flex items-center space-x-3 font-normal text-secondary;
  }

  & .show {
    @apply btn-secondary;
  }
}

.comfortRooms {
  & .title {
    @apply flex items-center space-x-3 font-normal text-body;
  }

  .light & {
    .title {
      @apply text-white;
    }
  }
}
