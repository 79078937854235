.root {
  @apply block w-full sm:w-auto rounded bg-secondary px-4 py-2 text-neutral-800 md:inline-block;
}

.title {
  @apply callout block text-center uppercase;
}
.smallTitle {
  @apply text-sm block text-center uppercase sm:callout;
}

.time {
  @apply flex justify-center text-center;
}

.value {
  @apply h1 block font-mono;
}
.smallValue {
  @apply h3 block font-mono sm:h2;
}

.label {
  @apply pr-4 text-xs;
}
