.root {
  @apply mt-20 space-y-24 divide-y divide-neutral-600 bg-neutral-800 py-12 text-white;
}

.presaleContainer {
  @apply container space-y-8;
}

.title {
  @apply h2 text-center md:big-title;
}

.comingSoon {
  @apply relative  pt-12;
}
